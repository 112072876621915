<template>
    <OModal ref="modal" :title="$t('Share') + `: ${originalRecord?.Name}`"
        @shown="() => { $refs.nameInput?.focus(); $refs.nameInput?.select() }">
        <div class="modal-body" v-if="layoutRecord">
            <div class="mb-3">
                <label for="layout_shareToOrgUnit" class="form-label">{{ $t('Org Unit') }}:</label>
                <OrgUnitLookup :bind="(sel) => { layoutRecord.OrgUnit_ID = sel.ID; layoutRecord.OrgUnit = sel.OrgUnit; checkOrgUnitForDefault(); }">
                    <template #orgunit>
                        <input class="form-control" v-model="layoutRecord.OrgUnit" id="layout_shareToOrgUnit">
                    </template>
                </OrgUnitLookup>
            </div>
            <div class="mb-3">
                <label for="layout_shareName" class="form-label">{{ $t('Name') }}:</label>
                <input class="form-control" ref="nameInput" v-model="layoutRecord.Name" id="layout_shareName">
            </div>
            <div class="mb-3">
                <label for="layout_shareDescription" class="form-label">{{ $t('Description') }}:</label>
                <OTextArea class="form-control" v-model="layoutRecord.Description" id="layout_shareDescription" noResize
                    autoGrow rows="2" style="max-height: 200px" />
            </div>

            <small class="text-muted">{{$t('This will create a copy of the layout and set it as shared to your chosen Org Unit')}}</small>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">{{ $t('Close') }}</button>
            <button :disabled="!layoutRecord.Name || isSharing" @click="share()" 
                type="button" class="btn btn-primary btn-sm">{{ $t('Share') }}</button>
        </div>
    </OModal>
</template>

<script lang="ts">
import type { ILayoutRecord } from 'o365.modules.DataObject.Layout.ts';
import type DataObject from 'o365.modules.DataObject.ts';

export interface ILayoutShareModalProps {
    dataObject: DataObject;
};
</script>

<script setup lang="ts">
import OModal from 'o365.vue.components.Modal.vue';
import OTextArea from 'o365.vue.components.TextArea.vue';
import context from 'o365.modules.Context.ts';
import OrgUnitLookup from 'o365.vue.components.OrgUnitsLookup.vue';
import { SharingCapabilitiesChecker } from 'o365.modules.DataObject.Layout.UpdatesRetriever.ts';
import { ref, computed } from 'vue';

const props = defineProps<ILayoutShareModalProps>();

const emit = defineEmits<{
    (e: 'shared'): void
}>();

const modal = ref(null);

const originalRecord = ref<ILayoutRecord | null>(null);
const layoutRecord = ref<ILayoutRecord | null>(null);
/** Indicates that there is a sharing request in progress */
const isSharing = ref(false);
const disableDefault = ref(true);
const isDefault = computed({
    get() { return layoutRecord.value.Default; },
    set(pValue) { 
        if (pValue) {
            layoutRecord.value.Name = layoutRecord.value.OrgUnit;
            layoutRecord.value.Description = 'Default shared layout';
        }
        layoutRecord.value.Default = pValue;
    }
});

const sharingHelper = SharingCapabilitiesChecker.getInstance();

async function checkOrgUnitForDefault() {
    disableDefault.value = true;
    layoutRecord.value.Name = `${originalRecord.value.Name} Copy`;
    layoutRecord.value.Description = originalRecord.value.Description;
    const hasNoDefault = await sharingHelper.orgUnitHasNoDefault(layoutRecord.value.OrgUnit_ID, props.dataObject.id, props.dataObject.appId);
    disableDefault.value = !hasNoDefault;
}

function open(item: ILayoutRecord) {
    if (item?.item == null) { return; }
    try {
        originalRecord.value = JSON.parse(JSON.stringify(item.item));
    } catch (ex) {
        return;
    }
    if (originalRecord.value == null) { return; }

    disableDefault.value = true;

    layoutRecord.value = {
        Name: `${originalRecord.value.Name} Copy`,
        Description: originalRecord.value.Description,
        OrgUnit_ID: context.id,
        OrgUnit: context.name,
        Default: false
    };

    checkOrgUnitForDefault();

    modal.value?.show();
}

/**
* Tries to construct layout record for sharing
*/
function constructRecordForSharing() {
    if (layoutRecord.value == null || originalRecord.value == null) {
        throw new Error('Layout record is empty');
    } else if (!layoutRecord.value.Name) {
        throw new Error('Layout name can not be empty');
    } if (layoutRecord.value.OrgUnit_ID == null) {
        throw new Error('No Org Unit selected for sharing');
    }

    const shareRecord = {
        ID: originalRecord.value.ID,
        Name: layoutRecord.value.Name,
        Description: layoutRecord.value.Descirption,
        OrgUnit_ID: layoutRecord.value.OrgUnit_ID,
        Default: layoutRecord.value.Default
    };

    return shareRecord;
}

async function share() {
    isSharing.value = true;
    try {
        const record = constructRecordForSharing();
        await props.dataObject.layoutManager.shareLayout(record);
        const {default: translate} = await import('o365.modules.translate.ts');
        const {default: alert} = await import('o365.controls.alert.ts');
        alert(translate('Layout shared'), 'success', { autohide: true });
        modal.value?.hide();
        emit('shared');
    } catch (ex) {
        const {default: alert} = await import('o365.controls.alert.ts');
        alert(ex.message);
    } finally {
        isSharing.value = false;
    }
}

defineExpose({ open });
</script>